import React from "react";
import { Helmet } from "react-helmet";

export default function NotFound(): any {
  return (
    <React.Fragment>
      <Helmet>
        <title>404</title>
      </Helmet>
      <main className="jumbotron customJumbo">
        <div className="container">
          <h1>404: Page Not found</h1>
        </div>
      </main>
    </React.Fragment>
  );
}
