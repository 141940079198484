import React from "react";
import { Helmet } from "react-helmet";

export default function Home(): any {
  return (
    <React.Fragment>
      <Helmet>
        <title>Mantz Vac Center</title>
      </Helmet>
      <img className="homeBg" src="/mv_bg.jpg" />
      <div className="customHomeBigSpacer"></div>
    </React.Fragment>
  );
}
