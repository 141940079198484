import React from "react";
import { Helmet } from "react-helmet";

export default function Services(): any {
  return (
    <React.Fragment>
      <Helmet>
        <title>Mantz Vac Center - Service &amp; Repair</title>
      </Helmet>
      <div className="customBgPages">
        <div className="container">
          <div className="customHorizontalLine"></div>
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <div className="customPageInfo">
                <h1>SERVICE & REPAIR: “Fast Expert Vacuum Service”</h1>
                <table>
                  <tr>
                    <td>
                      <ul>
                        <li>TriStar</li>
                        <li>Compact</li>
                        <li>Kirby</li>
                        <li>Kenmore</li>
                        <li>Rainbow</li>
                        <li>Oreck</li>
                        <li>Electrolux</li>
                        <li>Filter Queen</li>
                        <li>Dyson</li>
                        <li>Eureka</li>
                        <li>most other brands</li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>Sanitaire</li>
                        <li>Dirt Devil</li>
                        <li>Hoover</li>
                        <li>Bissel</li>
                        <li>Panasonic</li>
                        <li>Royal</li>
                        <li>Lindhaus</li>
                        <li>Cirrus</li>
                        <li>Riccar</li>
                        <li>SEBO</li>
                        <li>Shark</li>
                      </ul>
                    </td>
                  </tr>
                </table>
                <h1>PARTS, BAGS & FILTERS</h1>
                <table>
                  <tr>
                    <td>
                      <ul>
                        <li>Most brands</li>
                      </ul>
                    </td>
                  </tr>
                </table>
                <h1>USED & RE-CONDITIONED VACS</h1>
                <table>
                  <tr>
                    <td>
                      <ul>
                        <li>Large selection</li>
                      </ul>
                    </td>
                  </tr>
                </table>
                <h1>AUTHORIZED SALES & SERVICE OF</h1>
                <table>
                  <tr>
                    <td>
                      <ul>
                        <li>TriStar</li>
                        <li>Cirrus</li>
                        <li>Riccar</li>
                        <li>Lindhaus</li>
                        <li>SEBO</li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="col-xs-12 col-md-5">
              <div className="customPageInfo">
                <h1>
                  <a href="tel:9204525535">(920) 452-5535</a>
                </h1>
                <p>1320 Michigan Avenue</p>
                <p>Sheboygan, WI 53081</p>
                <h1>Store Hours</h1>
                <table style={{ marginLeft: "10px" }}>
                  <tr>
                    <td className="tdLeft">Monday – Thursday:&nbsp;</td>
                    <td className="tdRight">
                      9:00AM - 1:30PM, 2:00PM - 5:00PM
                    </td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Friday:&nbsp;</td>
                    <td className="tdRight">9:00AM - 1:30PM</td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Saturday:&nbsp;</td>
                    <td className="tdRight">9:00AM – 11:00AM</td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Sunday:&nbsp;</td>
                    <td className="tdRight">Closed</td>
                  </tr>
                </table>
                <h1>
                  <a
                    href="mailto:info@mantzvac.com?Subject=Website%20Email"
                    target="_top"
                  >
                    info@mantzvac.com
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="customHorizontalLine"></div>
    </React.Fragment>
  );
}
