import React from "react";
import { Helmet } from "react-helmet";

export default function About(): any {
  return (
    <React.Fragment>
      <Helmet>
        <title>Mantz Vac Center - About Us</title>
      </Helmet>
      <div className="customBgPages">
        <div className="container">
          <div className="customHorizontalLine"></div>
          <div className="row">
            <div className="col-xs-12 col-md-7">
              <div className="customPageInfo">
                <h1>ABOUT US</h1>
                <p>
                  Mantz Vac Center offers a large selection of new, used and
                  rebuilt vacuum cleaners. Only vacuums with high quality and
                  reliability make it to our showroom. Our vacuums clean better,
                  filter more & are easy to use, and long lasting. Many brands,
                  we handle, have fantastic warranties which we back with our #1
                  customer service reputation. We have supplies and service for
                  most makes and models. We are here to serve the people of
                  Sheboygan and surrounding areas offering “Healthy Home
                  Solutions”.
                  <br />
                  <br />
                  We look forward to serving you.
                </p>
                <h1>HISTORY</h1>
                <p>
                  In 1965 Gordon Mantz opened the doors to Mantz Vac Center. He
                  was a direct distributor for Kirby, Filter Queen and later for
                  Compact/TriStar vacuum cleaners. In 1975, Tom Mantz and Rick
                  Baumgart were hired as full-time independent sales people. In
                  1987, Tom Mantz (Gordy’s son) purchased the business, adding a
                  new page to Mantz Vac Center. Tom became a Factory Direct
                  Distributor for TriStar Vacuums. With the help of Rick
                  Baumgart they opened up several sub-distributor offices
                  throughout the state of Wisconsin. Beginning in the late
                  1990’s Tom and Rick slowly began concentrating on more service
                  and repairing of vacuums.
                  <br />
                  <br />
                  ** Due to a need to expand in 2010, Mantz Vac Center moved to
                  its current location at 1320 Michigan Avenue in Sheboygan.
                  Mantz Vac Center prides itself in their customer service. Our
                  #1 priority is to make you the customer # 1. Every customer is
                  important to us! It is our goal to not only satisfy the
                  customer with our expert and fast service but to maintain them
                  as a customer for life. Our promise to you the customer is:
                  “We will work hard. Work as long as it takes to complete the
                  job. Be honest, trustworthy and fair!”
                  <br />
                  <br />
                  Mantz Vac Center will continue the tradition that Gordy Mantz
                  started.
                  <br />
                  So, come see us at 1320 Michigan Ave, Sheboygan!!!
                  <br />
                  HAPPY CLEANING....
                  <br />
                  <br />
                  Tom Mantz and Rick Baumgart, Staff
                </p>
              </div>
            </div>
            <div className="col-xs-6 col-md-5">
              <div className="customPageInfo">
                <h1>
                  <a href="tel:9204525535">(920) 452-5535</a>
                </h1>
                <p>1320 Michigan Avenue</p>
                <p>Sheboygan, WI 53081</p>
                <h1>Store Hours</h1>
                <table style={{ marginLeft: "10px" }}>
                  <tr>
                    <td className="tdLeft">Monday – Thursday:&nbsp;</td>
                    <td className="tdRight">10:00AM - 4:00PM</td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Friday:&nbsp;</td>
                    <td className="tdRight">10:00AM - 1:00PM</td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Saturday:&nbsp;</td>
                    <td className="tdRight">9:00AM – 11:00AM</td>
                  </tr>
                  <tr>
                    <td className="tdLeft">Sunday:&nbsp;</td>
                    <td className="tdRight">Closed</td>
                  </tr>
                </table>
                <h1>
                  <a
                    href="mailto:info@mantzvac.com?Subject=Website%20Email"
                    target="_top"
                  >
                    info@mantzvac.com
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="customHorizontalLine"></div>
    </React.Fragment>
  );
}
