import React from "react";
import { Link } from "react-router-dom";

export default function Layout(props: { children: React.ReactNode }): any {
  return (
    <React.Fragment>
      <div
        style={{
          margin: "20px",
          backgroundColor: "#fff",
          padding: "20px",
          fontSize: "20px",
          color: "red",
          textAlign: "center",
          display: "none",
        }}
      >
        {
          "WE WILL BE CLOSED FEBRUARY 17 - MARCH 19, 2023 DUE TO VACATION! ......WE WILL REOPEN ON MARCH 20, 2023 AT 10:00AM"
        }
      </div>
      <div className="topContact">
        <div>
          Vacuums and Vacuum Service &nbsp;&nbsp;·&nbsp;&nbsp;{" "}
          <a href="tel:9204525535">(920) 452-5535</a> &nbsp;&nbsp;·&nbsp;&nbsp;{" "}
          <a
            href="mailto:info@mantzvac.com?Subject=Website%20Email"
            target="_top"
          >
            info@mantzvac.com
          </a>
        </div>
      </div>
      <header
        className="navbar navbar-static-top customHeader"
        role="navigation"
      >
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <Link className="brand" to="/"></Link>
          </div>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right customNavbarHeader">
              <li>
                <Link to="/" className="navbar-link">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/sales-services-repairs" className="navbar-link">
                  Sales - Service / Repair
                </Link>
              </li>
              <li>
                <Link to="/about" className="navbar-link">
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact" className="navbar-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      {props.children}
      <div className="customFooterAbove">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              {/* <div>&nbsp;</div>
              <div>&nbsp;</div> */}
              <table style={{ marginLeft: "auto", marginRight: "auto" }}>
                <tr>
                  <td className="tdLeft" style={{ textAlign: "left" }}>
                    1320 Michigan Avenue
                  </td>
                </tr>
                <tr>
                  <td className="tdLeft" style={{ textAlign: "left" }}>
                    Sheboygan, WI 53081
                  </td>
                </tr>
                <tr>
                  <td className="tdLeft" style={{ textAlign: "left" }}>
                    Phone:{" "}
                    <a href="tel:9204525535" style={{ color: "#ffffff" }}>
                      (920) 452-5535
                    </a>
                  </td>
                </tr>
                <tr>
                  <td className="tdLeft" style={{ textAlign: "left" }}>
                    Email:{" "}
                    <a
                      href="mailto:info@mantzvac.com?Subject=Website%20Email"
                      target="_top"
                      style={{ color: "#ffffff" }}
                    >
                      info@mantzvac.com
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div className="col-xs-12 col-sm-6">
              {/* <div style={{ textAlign: "center", color: "#faa" }}>
                {"OFFICE WILL BE CLOSED"}
              </div>
              <div style={{ textAlign: "center", color: "#faa" }}>
                {"FEBRUARY 17 - MARCH 19, 2023 DUE TO VACATION"}
              </div> */}
              <table style={{ marginLeft: "auto", marginRight: "auto" }}>
                <tr>
                  <td className="tdLeft">Monday – Thursday:&nbsp;</td>
                  <td className="tdRight">10:00AM - 4:00PM</td>
                </tr>
                <tr>
                  <td className="tdLeft">Friday:&nbsp;</td>
                  <td className="tdRight">10:00AM - 1:00PM</td>
                </tr>
                <tr>
                  <td className="tdLeft">Saturday:&nbsp;</td>
                  <td className="tdRight">9:00AM – 11:00AM</td>
                </tr>
                <tr>
                  <td className="tdLeft">Sunday:&nbsp;</td>
                  <td className="tdRight">Closed</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <footer className="customFooter">
        <p className="text-center" style={{ margin: "5px 0" }}>
          <small>
            &copy; {new Date().getFullYear()} - Mantz Vac Center, Inc.{" "}
          </small>
        </p>
      </footer>
    </React.Fragment>
  );
}
