import React from "react";
import Layout from "./Layout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import NotFound from "./pages/NotFound";

export default function App(): any {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/sales-services-repairs">
            <Services />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/">
            <NotFound />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}
